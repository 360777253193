<template>
  <div id="accordion-document" v-if="myPlaylistDoc.length">
    <DocumentItem v-for="(item, ind) in myPlaylistDoc" :key="ind" :ind="ind" :item="item" @delete="handleDelete" @updateState="updateState" />
    <div class="modal fade show" id="modal-confirm-delete-document" tabindex="-1" aria-modal="true" role="dialog" data-backdrop="static" data-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content rounded-1">
          <div class="modal-body p-2 p-xl-3 text-center">
            <div>
              <img src="~/assets/icons/library/delete.svg" alt="">
            </div>
            <div class="font-weight-bold my-2 my-xl-3 text-body">Xóa danh sách đã lưu</div>
            <div v-if="itemDelete">Hành động này sẽ xóa tất cả dữ liệu đã lưu trong <b>“{{itemDelete.name}}”</b>.</div>
            <div class="mt-1 mb-2 mb-xl-3">Bạn vẫn muốn xóa ?</div>
            <div class="action mb-2">
              <span class="text-body pointer mr-2 mr-xl-4" data-dismiss="modal">Hủy</span>
              <span class="text-primary pointer" @click="handleAccept">Đồng ý</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DocumentItem from './document-item.vue'
import Resource from '~/common/api/resource'
const playlistResource = new Resource('library/playlist')
export default {
  components: {
    DocumentItem
  },
  data () {
    return {
      itemDelete: null
    }
  },
  computed: {
    ...mapGetters([
      'myPlaylistDoc',
      'myPlaylistExam'
    ])
  },
  methods: {
    handleDelete (item = null) {
      if (item) {
        this.itemDelete = item
        $('#modal-confirm-delete-document').modal('show')
      }
    },
    updateState () {
      this.$emit('updateState')
    },
    handleAccept () {
      playlistResource.destroy(this.itemDelete.id)
        .then(() => {
          this.itemDelete = null
          $('#modal-confirm-delete-document').modal('hide')
          this.$emit('delete')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  #modal-confirm-delete-document{
    .modal-dialog{
      max-width: 20rem;
      @media (max-width: 1366px) {
      }
      @media (max-width: 1200px) {
      }
      @media (max-width: 992px) {
      }
      @media (max-width: 768px) {
      }
      @media (max-width: 576px) {
      }
      .modal-content{
        border-radius: 4px;
        .modal-body{
          color: #777777;
        }
      }
    }
  }
</style>
