<template>
  <div class="library-pagination" v-if="total > 0 && perPage < total">
    <b-pagination v-model="numberPage" @change="paginationData" :total-rows="total" :per-page="perPage" :limit="limit" first-number last-number :align="align">
      <template #prev-text>
        <img src="~/assets/icons/library/pagination/prev.svg" alt="">
      </template>
      <template #next-text>
        <img src="~/assets/icons/library/pagination/next.svg" alt="">
      </template>
    </b-pagination>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Number,
      default: 1,
      required: false
    },
    perPage: {
      type: Number,
      default: 10,
      required: false
    },
    limit: {
      type: Number,
      default: 5,
      required: false
    },
    total: {
      type: Number,
      default: 0,
      required: false
    },
    align: {
      type: String,
      default: 'center',
      required: false
    }
  },
  computed: {
    numberPage: {
      get () {
        return this.page
      },
      set (newValue) {
        return newValue
      }
    }
  },
  methods: {
    paginationData (value) {
      this.$emit('change', value)
    }
  }
}
</script>

<style lang="scss">
  .library-pagination {
		.pagination {
			gap: .625rem;
			.page-item {
				.page-link {
					background: #ffffff;
					color: #888888;
					font-weight: 500;
					font-size: 1rem;
					border: 1px solid #eeeeee;
					border-radius: 6px;
					width: 30px;
					height: 30px;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0;
				}
				&.active {
					.page-link {
						color: #4086ff;
					}
				}
			}
		}
	}
</style>
