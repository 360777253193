<template>
  <div class="h-100 card-prod" v-if="item" @click="checkBeforeRedirect">
    <div class="card card-small h-100 c-divider c-creator-tag c-mcq ">
      <div class="card-body">
        <div class="d-flex prod-title pointer">
          <img src="~/assets/icons/library/union-icon.svg" alt="" />
          <div class="limit-line-2">{{ item.name }}</div>
        </div>
        <div class="data-divider"></div>
        <div class="mcq-time-tag d-flex flex-wrap align-items-center">
          <div class="small-tag color-slightgrey2">
            {{ item.class_name }} &#8226; {{ item.category_name }}
          </div>
          <div class="mcq-time color-slightgrey2">
            <img src="~/assets/icons/library/time-circle.svg" alt=""> {{ item.time }} phút
          </div>
        </div>
        <div class="mcq-count-source d-flex flex-wrap align-items-center">
          <!-- <div class="creator-tag">
            <em>Nguồn:</em> {{ item.author }}
          </div> -->
          <div class="mcq-count color-slightgrey2">
            <img src="~/assets/icons/library/question-circle.svg" alt=""> {{ item.number_question }} câu
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  methods: {
    checkBeforeRedirect () {
      if (!this.$store.state.user) {
        this.$bvModal.show('login')
        return
      }
      const item = { ...this.item }
      item.is_free = 1
      this.$helper.checkUserBuySub(this, item, '/trac-nghiem/' + item.slug, 'Bạn cần mua gói thành viên để làm bài tập này')
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-prod {
    .card {
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
      border-color: #eaeaea;
      position: relative;
      &.card-small {
        border-color: transparent;
      }
      .follow-icon {
        position: absolute;
        top: 12px;
        right: 12px;
        z-index: 3;
      }
      &:not(.card-small):before {
        content: '';
        width: 100%;
        height: 55px;
        top: 0;
        left: 0;
        position: absolute;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0) 100%);
        border-radius: 8px 8px 0px 0px;
        z-index: 2;
      }
    }
    .card-body {
      padding: 13px !important;
      .prod-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        margin-bottom: 12px;
        display: flex;
        gap: 8px;
        align-items: flex-start;
      }
      .prod-users {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;
        margin-bottom: 10px;
        .user {
          display: flex;
          align-items: center;
          gap: 6px;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #777777;
        }
      }
      .data-divider {
        height: 1px;
        background-color: #e6e6e6;
      }
      .prod-video-live-stream-counter,
      .prod-video-counter {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        gap: 6px;
      }
      .prod-video-live-stream-counter {
        margin-bottom: 0;
      }
      .small-tag {
        background: #ffffff;
        border: 1px solid #f1f1f1;
        border-radius: 19.5px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #888888;
        padding: 2px 6px;
        width: fit-content;
        margin-top: 10px;
      }
    }
    .c-divider {
      .card-body {
        .prod-title {
          margin-bottom: 10px;
        }
      }
    }
    .c-creator-tag {
      .card-body {
        .creator-tag {
          margin-top: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #6b6d70;
          .creator-name {
            color: #4086ff;
          }
        }
      }
    }
    .c-mcq {
      .card-body {
        .small-tag,
        .creator-tag {
          margin: 0;
        }
        .mcq-time-tag,
        .mcq-count-source {
          margin-top: 10px;
          justify-content: space-between;
          .mcq-time,
          .mcq-count {
            // width: 80px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #888888;
          }
        }
      }
    }
  }
</style>
