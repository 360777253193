<template>
  <div class="thematic-related-container bg-white" v-if="datas.length">
    <div class="thematic-related-title font-size-18 fw-500 text-dark">Khóa học liên quan</div>
    <div class="thematic-list">
      <div class="mb-3" v-for="(it, ind) in datas" :key="ind">
        <div class="thematic-item d-flex pointer" @click="checkBeforeRedirect(it)">
          <ImageNuxt :src="it.avatar || 'lesson'" width="107" height="62" preset="topic" alt="" class="video_subject flex-shrink-0" />
          <div>
            <div class="thematic-item-title limit-line-2 mx-2 mb-1 font-size-14 text-dark fw-500">{{it.name}}</div>
            <div class="list-teacher mx-2 d-flex align-items-center font-size-15 text-grey-color limit-line-1">
              <span class="mr-2 flex-shrink-0" v-for="(teacher, idx) in it.teachers" :key="idx">
                <ImageNuxt type="avatar" :src="teacher.avatar || 'avatar'" height="20" class="rounded-circle mr-1" />{{ teacher.name }}
                <!-- <ImageCustom type="avatar" :src="teacher.avatar" height="20" class="rounded-circle mr-1" />{{ teacher.name }} -->
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Resource from '~/common/api/resource'
const relatedResource = new Resource('library/topic/relate')
export default {
  props: {
    topicId: {
      type: [String, Number],
      default: '',
      required: true
    }
  },
  data () {
    return {
      datas: []
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    async loadData () {
      if (!this.topicId) { return }
      const { data } = await relatedResource.list({ topic_id: this.topicId })
      this.datas = data
    },
    checkBeforeRedirect (item) {
      if (!this.user) {
        this.$bvModal.show('login')
        return
      }
      item.is_free = 1
      this.$helper.checkUserBuySub(this, item, '/khoa-hoc/' + item.slug)
    }
  }
}
</script>

<style lang="scss" scoped>
  .thematic-related-container{
    background-color: #fff;
    border-radius: 8px;
    padding: 17px 16px 12px 12px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    max-width: 100%;
    width: 26rem;
    height: 31rem;
    @media (max-width: 1366px) {}
    @media (max-width: 1200px) {}
    @media (max-width: 992px) {}
    @media (max-width: 768px) {}
    @media (max-width: 576px) {
      height: auto;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: #c4c4c4;
    }
    .thematic-related-title {
      line-height: 21px;
      margin-bottom: 15px;
    }
    .thematic-list{
      .thematic-item {
        padding: 6px;
        padding-right: 0px;
        border: 1px solid #ECECEC;
        border-radius: 7px;
        > img {
          border-radius: 7px;
        }
        .thematic-item-title {
          line-height: 128%;
        }
        .list-teacher{
          line-height: 18px;
        }
      }
    }
  }
</style>
