<template>
  <div v-if="item && item.number_file > 0" class="expand-wr mb-3 mb-lg-4" >
    <div :id="'expand-item-tab-'+item.id" :data-target="'#expand-item-content-'+item.id" :aria-controls="'expand-item-content-'+item.id" :aria-expanded="ind === 0 ? 'true' : 'false'" data-toggle="collapse" class="row no-gutters document-collapse pointer d-flex expand-item bg-white mt-2 mx-0">
      <div class="col-xl-8 col-8 d-flex align-items-center">
        <img src="~/assets/icons/library/folder-icon.svg" alt="folder" class="folder pr-2 pr-lg-3">
        <div class="document-name limit-line-1 pt-0" :class="{'d-flex flex-column': textError}">
          <p v-if="state === 'view'" class="fw-500 mb-0 folder-title">{{ item.name }} <span class="folder-counter">({{ query.total || item.number_file }})</span></p>
          <template v-else>
            <input class="py-2 px-2 px-lg-3" placeholder="Nhập tên playlist" v-model="playlistName" @focus.stop/>
            <div v-if="textError" class="text-danger small mt-1">{{textError}}</div>
          </template>
        </div>
      </div>
      <div class="col-xl-4 col-4">
        <div v-if="state === 'view'" class="d-flex justify-content-end align-items-center">
          <div class="p-1 mr-1"><img class="expand-icon transition" src="~/assets/icons/library/dropdownn.svg" alt=""></div>
          <div class="dropdown">
            <button class="dropdown-toggle p-1 ml-2 ml-lg-3 pointer btn-no-outline border-0 bg-transparent" type="button" :id="'dropdownMenuButton'+item.id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img src="~/assets/icons/library/three-dots.svg" alt="">
            </button>
            <div class="dropdown-menu" :aria-labelledby="'dropdownMenuButton'+item.id">
              <div class="dropdown-item" @click.stop="handleDelete">Xóa</div>
              <div v-if="!item.is_default" class="dropdown-item" @click.stop="handleEdit">Sửa</div>
            </div>
          </div>
        </div>
        <div v-else class="d-flex justify-content-end align-items-center actions">
          <button @click.stop="handleCancel" class="cancel py-2 px-4 border-0 text-white mr-3">Hủy</button>
          <button @click.stop="handleUpdate" class="update py-2 px-4 border-0 text-white">Lưu lại</button>
        </div>
      </div>
    </div>
    <div :id="'expand-item-content-'+item.id" :aria-labelledby="'expand-item-tab-'+item.id" class="collapse document-content" :class="{'show': ind === 0}" data-parent="#accordion-document">
      <div v-if="loading" class="list-it m-spinner">
        <b-spinner small type="grow"></b-spinner>
        <b-spinner small type="grow"></b-spinner>
        <b-spinner small type="grow"></b-spinner>
      </div>
      <div v-else class="list-it">
        <div class="item d-flex bg-white mt-1 align-items-center justify-content-between w-100 px-0" v-for="(it, idx) in datas" :key="idx" >
          <img src="~/assets/icons/library/pink-pdf.svg" height="41" class="mr-2 mr-lg-3 flex-shrink-0" alt="">
          <div class="pl-1" @click="checkBeforeRedirect(it)">
            <div class="doc-info">
              <p class="doc-name limit-line-2 pointer">{{ it.name }}</p>
              <div class="small-tag bg-white color-slightgrey2">{{ it.class_name }} &#8226; {{ it.category_name }}</div>
            </div>
          </div>
          <div class="action d-flex align-items-center justify-content-end w-25 ml-auto">
            <!-- <div class="creator-tag font-italic mr-5" v-if="it.source">
              Biên soạn: <span class="creator-name">{{it.source}}</span>
            </div> -->
            <div class="btn-remove pointer">
              <img src="~/assets/icons/library/trash-bin.svg" alt="" @click="handleUnSave(it)">
            </div>
          </div>
        </div>
        <NoData class="col-12" v-if="!datas.length" />
      </div>
      <div v-if="datas.length < query.total" class="btn-read-more text-primary text-center pointer" @click="loadFile(query.page + 1)">Xem thêm</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NoData from '~/components/nodata/new.vue'
import Resource from '~/common/api/resource'
// const playlistAllResource = new Resource('library/playlist/all')
const playlistFileResource = new Resource('library/playlist/file')
const playlistResource = new Resource('library/playlist')
export default {
  components: {
    NoData
  },
  props: {
    item: {
      type: Object,
      default: null,
      required: false
    },
    ind: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      query: {
        page: 1,
        limit: 10,
        total: 0,
        playlist_id: this.item.id
      },
      datas: [],
      state: 'view',
      playlistName: '',
      textError: '',
      loading: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    ...mapGetters([
      'myPlaylistDoc',
      'myPlaylistExam'
    ])
  },
  watch: {
    item () {
      this.query.playlist_id = this.item.id
      this.init()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      if (this.ind === 0) {
        this.loadFile()
      }
      $(document).on('show.bs.collapse', '#accordion-document', (e) => {
        if ('expand-item-content-' + this.item.id === e.target.id && !this.datas.length && this.item.number_file > 0) {
          this.loadFile()
        }
      })
    },
    async loadFile (page = 1) {
      if (this.loading) { return }
      this.loading = true
      this.query.page = page
      const { data, total } = await playlistFileResource.list(this.query, this.token)
      this.query.total = total
      if (page === 1) {
        this.datas = data
      } else {
        this.datas.push(...data)
      }
      this.loading = false
    },
    handleDelete () {
      this.$emit('delete', this.item)
    },
    handleEdit () {
      this.state = 'edit'
      this.textError = ''
      this.playlistName = this.item.name
    },
    handleCancel () {
      this.state = 'view'
    },
    handleUpdate () {
      if (!this.playlistName.length) {
        this.textError = '*Tên danh sách không được để trống'
        return
      }
      if (this.playlistName.length > 200) {
        this.textError = '*Tên danh sách không được hơn 200 ký tự'
        return
      }
      if (this.loading) { return }
      this.loading = true
      const data = {
        playlist_name: this.playlistName
      }
      playlistResource.update(this.item.id, data)
        .then((res) => {
          this.setNamePlaylist(this.playlistName)
          this.state = 'view'
          this.$emit('updateState')
        })
        .catch((err) => {
          this.textError = err.response.data.message
        })
        .finally(() => {
          this.loading = false
          this.playlistName = ''
        })
    },
    checkBeforeRedirect (item) {
      if (!this.user) {
        this.$bvModal.show('login')
        return
      }
      this.$helper.checkUserBuySub(this, item, '/tai-lieu/' + item.slug, 'Bạn cần mua gói thành viên để xem tài liệu này')
    },
    handleUnSave (item) {
      playlistFileResource.destroy(item.id)
        .then((res) => {
          this.setNumberPlaylist(this.item.number_file - 1)
          this.$emit('updateState')
        }).catch(() => {})
    },
    setNumberPlaylist (number) {
      this.$store.commit('context/SET_NUMBER_MY_PLAYLIST_DOC', { ind: this.ind, number })
      if (number > 0) {
        this.loadFile()
      }
    },
    setNamePlaylist (name) {
      this.$store.commit('context/SET_NAME_MY_PLAYLIST_DOC', { ind: this.ind, name })
    }
  }
}
</script>

<style lang="scss" scoped>
  .expand-wr{
    box-shadow: 0px 3px .625rem rgba(0, 0, 0, 0.1);
    border-radius: .5rem;
    @media (max-width: 1366px) {
    }
    @media (max-width: 1200px) {
    }
    @media (max-width: 992px) {
    }
    @media (max-width: 768px) {
    }
    @media (max-width: 576px) {
    }
    .document-collapse{
      border-bottom: 1px solid #f1f1f1;
      padding: 2rem;
      @media (max-width: 1366px) {
        padding: 1.25rem;
      }
      @media (max-width: 1200px) {
      }
      @media (max-width: 992px) {
      }
      @media (max-width: 768px) {
      }
      @media (max-width: 576px) {
        padding: 1rem;
      }
      &[aria-expanded='true'] {
        .expand-icon{
          transform: rotateZ(180deg);
        }
      }
      .folder-counter{
        color: #777777;
      }
      .document-name{
        input{
          background: #FFFFFF;
          border: 1px solid #EBEBEB;
          border-radius: 4px;
        }
      }
      .actions{
        button{
          border-radius: 1.25rem;
          &.cancel{
            background: #CBCBCB;
          }
          &.update{
            background: var(--primary);
          }
        }
      }
    }
    .document-content{
      padding: 0 3rem;
      @media (max-width: 1366px) {
        padding: 0 2rem;
      }
      @media (max-width: 1200px) {
      }
      @media (max-width: 992px) {
      }
      @media (max-width: 768px) {
      }
      @media (max-width: 576px) {
        padding: 0 1.25rem;
      }
      .list-it{
        .item{
          border-bottom: 1px solid #f1f1f1;
          padding: 1.5rem 0;
          @media (max-width: 1366px) {
            padding: 1rem 0;
          }
          @media (max-width: 1200px) {
          }
          @media (max-width: 992px) {
          }
          @media (max-width: 768px) {
          }
          @media (max-width: 576px) {
            padding: .75rem 0;
          }
          &:last-child{
            border-bottom: 0;
          }
          .doc-info {
            .doc-name {
              margin-bottom: .625rem;
              font-size: 1rem;
              color: #222222;
            }
            .small-tag {
              border: 1px solid #f1f1f1;
              border-radius: 1.25rem;
              font-size: .875rem;
              color: #888888;
              padding: 0.35rem;
              width: fit-content;
              @media (max-width: 1366px) {
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 992px) {
              }
              @media (max-width: 768px) {
              }
              @media (max-width: 576px) {
              }
            }
          }
          .action{
            .creator-tag {
              font-size: .875rem;
              color: #888888;
            }
          }
        }
        &.m-spinner {
          margin-top: 0.25rem;
          padding: 2.73rem;
          text-align: center;
          .spinner-grow {
            width: 0.7rem;
            height: 0.7rem;
            margin-right: 0.5rem;
            color: #777;
          }
        }
      }
      .btn-read-more{
        font-size: 1rem;
        padding: 1.25rem;
        @media (max-width: 1366px) {
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 992px) {
        }
        @media (max-width: 768px) {
        }
        @media (max-width: 576px) {
        }
      }
    }
  }
</style>
