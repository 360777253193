<template>
  <div class="quiz-item-v2 pointer" v-if="item" >
    <div v-if="!viewList" class="card flex-row flex-nowrap">
      <img v-if="item.label" :src="item.label" class="free-badge position-absolute" alt="">
      <div class="card-body overflow-hidden d-flex shadow-none">
        <div class="w-100">
          <p class="prod-title d-flex align-items-center" @click="checkBeforeRedirect">
            <img src="~/assets/icons/library/mcq-icon.svg" alt="">
            <span class="limit-line-2 color-darkgrey">{{ item.name }}</span>
          </p>
          <div class="d-flex align-items-center tag-n-markup" @click="checkBeforeRedirect">
            <div class="small-tag bg-white color-slightgrey2">{{ item.class_name }} &#8226; {{ item.category_name }}</div>
            <div class="mcq-time color-slightgrey2 d-flex">
              <img class="mr-1" src="~/assets/icons/library/time-circle.svg" alt=""> {{ item.time }} phút
            </div>
            <div class="mcq-time color-slightgrey2 d-flex">
              <img class="mr-1" src="~/assets/icons/library/question-circle.svg" alt=""> {{ item.number_question }} câu
            </div>
          </div>
          <!-- <div class="creator-tag font-italic ">
            Nguồn: <span class="creator-name color-grey">{{ item.author }}</span>
          </div> -->
        </div>
        <div v-if="action" class="actions mt-1 mt-lg-2 ml-2 ml-lg-3 flex-shrink-0" :class="{'active': item.is_saved}">
          <span @click="openDropdown">
            <span class="mr-1" v-html="require('~/assets/icons/library/markup.svg?raw')"></span>
            <span>{{item.is_saved ? 'Bỏ lưu' : 'Lưu'}}</span>
          </span>
          <Playlist field="exam_id" :item="item" :open="open" @close="open = false" @addData="addData" :delete-id="deleteId" class-button="bottom-0 right-0" />
        </div>
      </div>
    </div>
    <div v-else class="card h-100" :class="{'card-list-view': viewList}">
      <img v-if="item.label" :src="item.label" class="free-badge position-absolute" alt="">
      <div class="card-body h-100">
        <div class="prod-title d-flex align-items-center" @click="checkBeforeRedirect">
          <div class="file-type-img">
            <img src="~/assets/icons/library/mcq-icon.svg" height="38" alt="">
          </div>
          <span class="limit-line-2 color-darkgrey">{{ item.name }}</span>
        </div>
        <div class="data-divider"></div>
        <div class="d-flex align-items-center justify-content-between tag-n-markup">
          <div class="small-tag color-slightgrey2">{{ item.class_name }} &#8226; {{ item.category_name }}</div>
          <div v-if="action" class="markup pointer actions flex-shrink-0" :class="{'active': item.is_saved}" >
            <span @click="openDropdown">
              <span class="mr-1" v-html="require('~/assets/icons/library/markup.svg?raw')"></span>
              <span>{{item.is_saved ? 'Bỏ lưu' : 'Lưu'}}</span>
            </span>
            <Playlist field="exam_id" :item="item" :open="open" @close="open = false" @addData="addData" :delete-id="deleteId" class-button="bottom-0 right-0" />
          </div>
        </div>
        <div class="mcq-time-counter d-flex align-items-center justify-content-between">
          <div class="mcq-time color-slightgrey2">
            <img src="~/assets/icons/library/time-circle.svg" alt=""> {{ item.time }} phút
          </div>
          <div class="mcq-time color-slightgrey2">
            <img src="~/assets/icons/library/question-circle.svg" alt=""> {{ item.number_question }} câu
          </div>
        </div>
        <!-- <div class="creator-tag">
          Biên soạn: <span class="creator-name text-primary">{{item.author}}</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Playlist from '~/components/library-v2/playlist'
import { checkAllow } from '~/common/api/check-register'
export default {
  components: {
    Playlist
  },
  props: {
    item: {
      type: Object,
      default: null,
      required: false
    },
    viewList: {
      type: Boolean,
      default: true
    },
    action: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data () {
    return {
      deleteId: '',
      open: false,
      processing: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    addData (state) {
      this.deleteId = ''
      this.open = false
      // eslint-disable-next-line vue/no-mutating-props
      this.item.is_saved = state
    },
    UnSave () {
      this.deleteId = this.item.id
    },
    openDropdown () {
      if (!this.action) {
        this.checkBeforeRedirect()
        return
      }
      if (!this.user) { // chưa đăng nhập
        this.$bvModal.show('modal-login')
        return
      }
      if (this.item.is_saved) {
        this.UnSave()
        return
      }
      this.open = true
    },
    async checkBeforeRedirect () {
      if (!this.user) {
        this.$router.push('/trac-nghiem/' + this.item.slug)
        return
      }
      const { data } = await checkAllow({
        class_id: this.item.class_id,
        type: 'EXAM',
        object_id: this.item.id
      })
      if (data.quiz === 0) {
        this.$store.dispatch('showStopModal', {
          type: 'EXAM',
          classId: this.item.class_id,
          dataFree: data.quiz_free
        })
      } else {
        this.$helper.checkUserBuySub(this, this.item, '/trac-nghiem/' + this.item.slug, 'Bạn cần mua gói thành viên để làm bài này')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.quiz-item-v2{
  .card {
    .card-body {
      border-radius: .5rem;
      box-shadow: 0px .25rem 1rem rgba(0, 0, 0, 0.1);
      color: #888888;
      padding: .75rem;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {
        padding: .5rem;
      }
      .prod-title {
				font-weight: 500;
				font-size: 1.125rem;
				line-height: 1.4;
				gap: .5rem;
				margin-bottom: .75rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          margin-bottom: .5rem;
        }
			}
      .file-type-img {
        width: 2rem;
        display: contents;
      }
      .data-divider {
        height: 1px;
        background-color: #e6e6e6;
      }
			.markup {
				font-size: .875rem;
				line-height: 1.2;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {}
			}
			.tag-n-markup {
				margin-top: .75rem;
				gap: 1.5rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          margin-top: .5rem;
          gap: 1rem;
        }
        .small-tag {
          border: 1px solid #f1f1f1;
          border-radius: 1.25rem;
          font-size: .875rem;
          line-height: 1.2;
          padding: .35rem;
          @media (max-width: 1366px) {}
          @media (max-width: 1200px) {}
          @media (max-width: 992px) {}
          @media (max-width: 768px) {}
          @media (max-width: 576px) {}
        }
				.mcq-time {
					font-weight: 400;
					font-size: .875rem;
					line-height: 1.2;
          @media (max-width: 1366px) {}
          @media (max-width: 1200px) {}
          @media (max-width: 992px) {}
          @media (max-width: 768px) {}
          @media (max-width: 576px) {}
				}
			}
      .markup-btn {
        gap: .5rem;
        font-size: .875rem;
        line-height: 1.2;
        margin-right: .35rem;
        margin-top: 1.5rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          margin-top: 1rem;
        }
      }
      .creator-tag {
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.2;
        margin-top: .75rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          margin-top: .5rem;
        }
      }
    }
    &.card-list-view{
      .prod-title {
        line-height: 1.2;
				min-height: 2rem;
        @media (max-width: 1366px) {
          min-height: 2rem;
        }
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          min-height: 2rem;
        }
			}
      .mcq-time-counter {
				margin-top: .75rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          margin-top: .5rem;
        }
				.mcq-time {
					font-size: .875rem;
					line-height: 1.2;
          @media (max-width: 1366px) {}
          @media (max-width: 1200px) {}
          @media (max-width: 992px) {}
          @media (max-width: 768px) {}
          @media (max-width: 576px) {}
				}
			}
    }
  }
  .free-badge {
    z-index: 2;
    top: .55rem;
    left: -.2rem;
    height: 1.625rem;
    @media (max-width: 1366px) {}
    @media (max-width: 1200px) {}
    @media (max-width: 992px) {}
    @media (max-width: 768px) {}
    @media (max-width: 576px) {}
  }
}
</style>
