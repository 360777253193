<template>
  <div class="normal-filter d-flex align-items-center justify-content-center ml-auto m-lg-auto">
    <div class="dropdown select-fk">
      <p class="btn d-flex align-items-center justify-content-between dropdown-toggle mb-0 selected-data bg-white color-darkgrey" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{ active ? active.name : placeholder }}
        <img src="~/assets/icons/library/chevron-dropdown.svg" alt="" />
      </p>
      <div class="dropdown-menu select-items" aria-labelledby="dropdownMenuButton">
        <template v-if="topicActive || active.tab === 'TOPIC'" >
          <div class="dropdown-item pointer bg-transparent" :class="{'on-active': active && active.type === 'topic'}" @click="setActive('TOPIC', 'topic', 'Khóa học')">Khóa học</div>
<!--          <div class="dropdown-item pointer bg-transparent" :class="{'on-active': active && active.type === 'video'}" @click="setActive('TOPIC', 'video', 'Video')">Video</div>-->
        </template>
        <template v-if="documentActive || active.tab === 'DOCUMENT'" >
          <div class="dropdown-item pointer bg-transparent" :class="{'on-active': active && !active.type}" @click="setActive('DOCUMENT', '', 'Tất cả')">Tất cả</div>
<!--          <div class="dropdown-item pointer bg-transparent" :class="{'on-active': active && active.type === 'pdf'}" @click="setActive('DOCUMENT', 'pdf', 'PDF')">PDF</div>-->
        </template>
        <template v-if="quizActive || active.tab === 'EXAM'" >
          <div class="dropdown-item pointer bg-transparent" :class="{'on-active': active && !active.type}" @click="setActive('EXAM', '', 'Trắc nghiệm')">Trắc nghiệm</div>
<!--          <div class="dropdown-item pointer bg-transparent" :class="{'on-active': active && active.type === 'vip'}" @click="setActive('EXAM', 'vip', 'Vip')">Vip</div>-->
<!--          <div class="dropdown-item pointer bg-transparent" :class="{'on-active': active && active.type === 'free'}" @click="setActive('EXAM', 'free', 'Free')">Free</div>-->
        </template>
      </div>
    </div>
    <div class="search-block d-flex align-items-center bg-white w-100 overflow-hidden">
      <img src="~/assets/icons/library/lookup-light.svg" class="ml-2 ml-lg-3" alt="">
      <input type="text" class="color-darkgrey border-0 outline-none w-100" v-model="keyword" placeholder="Nhập từ khóa tìm kiếm" @keyup.enter="handerSearch" />
      <div class="append-block pointer h-100 text-white d-none d-lg-flex align-items-center text-nowrap" @click="handerSearch">Tìm kiếm</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'video'
    }
  },
  data () {
    return {
      active: null,
      keyword: '',
      processing: false
    }
  },
  computed: {
    placeholder () {
      switch (this.type) {
        case 'video':
          return 'Khóa học'
        case 'document':
          return 'Tất cả'
        case 'quiz':
          return 'Trắc nghiệm'
        default:
          return 'Khóa học'
      }
    },
    topicActive () {
      const whiteList = ['thu-vien-khoa-hoc']
      return whiteList.includes(this.$route.name)
    },
    documentActive () {
      const whiteList = ['thu-vien-tai-lieu']
      return whiteList.includes(this.$route.name)
    },
    quizActive () {
      const whiteList = ['thu-vien-de-thi']
      return whiteList.includes(this.$route.name)
    }
  },
  watch: {
    $route (newValue, oldValue) {
      this.setQuery()
    }
  },
  created () {
    this.setQuery()
  },
  methods: {
    handerSearch () {
      if (this.processing) { return }
      this.keyword = this.keyword.trim()
      // if (!this.keyword) { return }
      if (this.keyword.length > 100) {
        this.keyword = this.keyword.substring(0, 100)
      }
      this.processing = true
      const query = { ...this.$route.query, ...this.active }
      query.keyword = encodeURIComponent(this.keyword)
      this.$router.push({ path: '/thu-vien/search', query })
      this.processing = false
    },
    setActive (tab, type, name) {
      this.active = { tab, type, name }
    },
    setQuery () {
      const tab = this.$route.query?.tab
      if (tab) {
        const { tab, type, name } = this.$route.query
        this.active = { tab, type, name }
      } else {
        switch (this.type) {
          case 'video':
            this.active = { tab: 'TOPIC', type: 'topic', name: 'Khóa học' }
            break
          case 'document':
            this.active = { tab: 'DOCUMENT', type: '', name: 'Tất cả' }
            break
          case 'quiz':
            this.active = { tab: 'EXAM', type: '', name: 'Trắc nghiệm' }
            break
          default:
            this.active = { tab: '', type: '', name: '' }
            break
        }
      }
      this.keyword = this.$route.query.keyword ? decodeURIComponent(this.$route.query?.keyword) : ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .normal-filter{
    width: 80%;
    gap: 1rem;
    max-width: 50rem;
    @media (max-width: 1366px) {}
    @media (max-width: 1200px) {}
    @media (max-width: 992px) {}
    @media (max-width: 768px) {}
    @media (max-width: 576px) {
      width: 100%;
      gap: .5rem;
      max-width: 22.5rem;
    }
    .select-fk {
      .selected-data {
        border: 1px solid #e6e6e6;
        border-radius: .625rem;
        font-size: 1rem;
        line-height: 1.3;
        padding: .875rem 1rem;
        width: 10rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          padding: .75rem .875rem;
          width: 8rem;
        }
      }
      .select-items {
        border: 1px solid #e6e6e6;
        border-radius: .625rem;
        .dropdown-item {
          font-size: 1rem;
          line-height: 1.3;
          color: #a5a5a5;
          @media (max-width: 1366px) {}
          @media (max-width: 1200px) {}
          @media (max-width: 992px) {}
          @media (max-width: 768px) {}
          @media (max-width: 576px) {}
          &.on-active {
            font-weight: 500;
            color: #333333;
          }
        }
      }
    }
    .search-block {
      border: 1px solid #e6e6e6;
      border-radius: .625rem;
      height: 3rem;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {}
      input {
        padding: 0 .75rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {}
        &::placeholder {
          color: #cbcbcb;
        }
      }
      .append-block {
        background: #888888;
        padding: 0 .625rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {}
      }
    }
  }
</style>
