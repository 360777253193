<template>
  <div class="document-item-v2 pointer" v-if="item">
    <div v-if="!viewList" class="card flex-row flex-nowrap">
      <LockFile v-if="!item.is_free"/>
      <div class="card-body overflow-hidden d-flex align-items-center shadow-none">
        <img v-if="item.label" :src="item.label" class="free-badge position-absolute" alt="">
        <div class="w-100" @click="checkBeforeRedirect">
          <p class="doc-title d-flex align-items-center" >
            <img v-if="item.file_type === 'application/pdf'" src="~/assets/icons/library/pink-pdf.svg" alt="">
            <img v-else src="~/assets/icons/library/blue-doc.svg" alt="">
            <span class="limit-line-2">{{ item.name }}</span>
          </p>
          <div class="d-flex align-items-center justify-content-between tag-n-markup">
            <div class="small-tag color-slightgrey2">
              {{ item.class_name }} &#8226; {{ item.category_name }}
            </div>
          </div>
          <!-- <div class="creator-tag font-italic ">
            Nguồn: <span class="creator-name color-grey">{{item.source}}</span>
          </div> -->
        </div>
        <div v-if="action" class="actions ml-3 flex-shrink-0" :class="{'active': item.is_saved}" >
          <span @click="openDropdown">
            <span class="mr-1" v-html="require('~/assets/icons/library/markup.svg?raw')"></span>
            <span>{{item.is_saved ? 'Bỏ lưu' : 'Lưu'}}</span>
          </span>
          <Playlist field="file_id" :item="item" :open="open" @close="open = false" @addData="addData" :delete-id="deleteId" class-button="bottom-0 right-0" />
        </div>
      </div>
    </div>
    <div v-else class="card h-100" :class="{'card-list-view': viewList}">
      <img v-if="item.label" :src="item.label" class="free-badge position-absolute" alt="">
      <div class="card-body h-100">
        <div class="doc-title d-flex align-items-center mb-2" @click="checkBeforeRedirect">
          <div class="file-type-img">
            <img v-if="item.file_type === 'application/pdf'" src="~/assets/icons/library/pink-pdf.svg" alt="pdf" height="41" width="25">
            <img v-else src="~/assets/icons/library/blue-doc.svg" alt="doc" height="41" width="25">
          </div>
          <span class="limit-line-2 color-darkgrey">{{ item.name }}</span>
        </div>
        <div class="data-divider"></div>
        <div class="d-flex align-items-center justify-content-between tag-n-markup">
          <div class="small-tag color-slightgrey2">
            {{ item.class_name }} &#8226; {{ item.category_name }}
          </div>
          <div v-if="action" class="pointer actions flex-shrink-0" :class="{'active': item.is_saved}" >

            <span @click="openDropdown">
              <span class="mr-1" v-html="require('~/assets/icons/library/markup.svg?raw')"></span>
              <span>{{item.is_saved ? 'Bỏ lưu' : 'Lưu'}}</span>
            </span>
            <Playlist field="file_id" :item="item" :open="open" @close="open = false" @addData="addData" :delete-id="deleteId" class-button="bottom-0 right-0" />
          </div>
        </div>
        <!-- <div class="creator-tag font-italic ">
          Biên soạn: <span class="creator-name color-grey">{{item.source}}</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Playlist from '~/components/library-v2/playlist'
export default {
  components: {
    Playlist
  },
  props: {
    item: {
      type: Object,
      default: null,
      required: false
    },
    viewList: {
      type: Boolean,
      default: true
    },
    action: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data () {
    return {
      deleteId: '',
      open: false,
      processing: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    addData (state) {
      this.deleteId = ''
      this.open = false
      // eslint-disable-next-line vue/no-mutating-props
      this.item.is_saved = state
    },
    UnSave () {
      this.deleteId = this.item.id
    },
    openDropdown () {
      if (!this.action) {
        this.checkBeforeRedirect()
        return
      }
      if (!this.user) { // chưa đăng nhập
        this.$bvModal.show('login')
        return
      }
      if (this.item.is_saved) {
        this.UnSave()
        return
      }
      this.open = true
    },
    checkBeforeRedirect () {
      if (!this.user) {
        this.$router.push('/tai-lieu/' + this.item.slug)
        return
      }
      this.$helper.checkUserBuySub(this, this.item, '/tai-lieu/' + this.item.slug, 'Bạn cần mua gói thành viên để xem tài liệu này')
    }
  }
}
</script>

<style lang="scss" scoped>
.document-item-v2{
  .card {
    .card-body {
      border-radius: .5rem;
      box-shadow: 0px .25rem 1rem rgba(0, 0, 0, 0.1);
      padding: .8125rem;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {
        padding: .75rem;
      }
      .doc-title {
        font-weight: 500;
        line-height: 1.3;
        gap: .5rem;
        font-size: 1.125rem;
        @media (max-width: 1366px) {
          font-size: 1rem;
        }
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          gap: .375rem;
        }
      }
      .file-type-img {
        width: 3rem;
      }
      .data-divider {
        height: 1px;
        background-color: #e6e6e6;
      }
      .small-tag {
        background: #ffffff;
        border: 1px solid #f1f1f1;
        border-radius: 1.125rem;
        font-size: .875rem;
        line-height: 1.2;
        color: #888888;
        padding: .375rem;
        width: fit-content;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {}
      }
      .tag-n-markup {
        margin-top: .75rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          margin-top: .5rem;
        }
      }
      .creator-tag {
        font-size: .875rem;
        line-height: 1.2;
        color: #6b6d70;
        margin-top: .625rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          margin-top: .5rem;
        }
        .creator-name {
          color: #4086ff;
          &.color-grey {
            color: #888888;
          }
        }
      }
      .actions {
        min-width: 4.37rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          min-width: 4rem;
        }
      }
    }
  }
  .free-badge {
    z-index: 2;
    top: .625rem;
    left: -.2rem;
    height: 1.625rem;
    @media (max-width: 1366px) {}
    @media (max-width: 1200px) {}
    @media (max-width: 992px) {}
    @media (max-width: 768px) {}
    @media (max-width: 576px) {}
  }
}
</style>
