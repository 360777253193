<template>
  <div v-if="item" >
    <div class="card-prod pointer" @click="checkBeforeRedirect">
      <div class="card position-relative overflow-hidden">
        <ImageCustom type="lesson" class="card-img-top" :src="item.thumbnail" alt="Card image cap" />
        <div class="card-body">
          <p class="prod-title font-weight-bold limit-line-1">{{ item.name }}</p>
          <div class="prod-users d-flex flex-wrap align-items-center">
            <div class="user d-flex align-items-center color-slightgrey2" v-for="(teacher, ind) in item.teachers" :key="ind">
              <ImageCustom :src="teacher.avatar" alt="avatar" class="user-avatar rounded-circle" width="24" height="24" type="avatar" />
              {{ teacher.fullName || teacher.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    checkBeforeRedirect () {
      if (!this.user) {
        this.$bvModal.show('login')
        return
      }
      const item = { ...this.item }
      this.$helper.checkUserBuySub(this, item, '/video/' + item.slug, 'Bạn cần mua gói thành viên để xem video này')
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-prod {
    .card {
      border-radius: 8px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
      border-color: #eaeaea;
      .card-img-top {
        aspect-ratio: 327 / 193;
      }
      .card-body {
        padding: 13px;
        .prod-title {
          font-size: 16px;
          margin-bottom: 12px;
          gap: 8px;
        }
        .prod-users {
          gap: 8px;
          margin-bottom: 10px;
          min-height: 24px;
          .user {
            gap: 6px;
            font-size: 15px;
            color: #777777;
          }
        }
      }
    }
  }
</style>
