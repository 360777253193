<template>
  <div class="quiz-layout-make-quiz-modal" v-if="item">
    <div class="quiz-header py-3 px-4 text-white bg-primary">
      <div class="row no-gutters">
        <div class="col-5 col-sm-4 col-lg-3">
          <nuxt-link to="/">
            <img src="/icon/library/quiz/logo.svg" />
          </nuxt-link>
        </div>
        <div class="col-7 col-sm-8 col-lg-9 quiz-name text-truncate font-weight-bold">
          {{ detail.name }}
        </div>
      </div>
    </div>
    <div class="quiz-sub-header p-2 bg-white" :class="{'quiz-sticky': quizSticky}">
      <div class="d-flex align-items-center justify-content-end">
        <span class="quiz-time font-weight-bold text-danger mr-4"><img src="/icon/library/time.svg"/>
          {{$helper.convertTimeLeft(timeLeft)}}
        </span>
        <!-- <button class="quiz-pause bg-white text-primary py-2 px-4 mr-4" v-if="started && !showNotifyPauseStart" @click.prevent="checkBeforePause()">Tạm dừng</button> -->
        <!-- <button class="quiz-continue bg-white text-warning py-2 px-4 mr-4" v-else @click.prevent="handerContinute()">Tiếp tục</button> -->
        <button class="quiz-submit bg-primary text-white border-0 font-weight-bold" @click.prevent="checkBeforeSubmit()">Nộp bài</button>
      </div>
    </div>
    <div class="quiz-content pt-3 px-3 pb-5">
      <div class="row no-gutters">
        <div class="col-sm-9 quiz-list-question bg-white">
          <Question v-for="(question,index) in detail.questions" :key="question.id" :item="question" :index="index" :exam-id="detail.id" :started="started" type-question="take" @reasonQuestion="reasonQuestion"/>
        </div>
        <div class="col-sm-3 quiz-list-short" ref="quiz-list-short">
          <div :class="{'quiz-sticky': quizSticky}" :style="{width: quizSticky ? $refs['quiz-list-short'].clientWidth+'px' : ''}">
            <div class="quiz-list-short-form bg-white rounded-sm text-primary pb-2">
              <div class="title font-weight-bold text-center py-2">Câu hỏi</div>
              <div class="quiz-list-question-short scrollbar">
                <div class="custom-row">
                  <div class="custom-col-2 quiz-question-answer" v-for="(question,index) in detail.questions" :key="question.id" @click="scrollToQuestion(index)" :class="{'primary' : question.is_answered}">
                    <span class="position-relative float-left d-inline-block number-answer">
                      <span class="number-answer-character position-center pointer" :class="{'big-answer': detail.questions.length > 99}">{{ index + 1 }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="quiz-status">Hoàn thành: <span class="font-weight-bold">{{answered}}/{{detail.number_question}}</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="notify-pause-start position-fixed bg-white text-center text-warning" :style="{opacity: showNotifyPauseStart ? 1 : 0}">
      Chọn ‘’Tiếp tục” để tiếp tục làm bài
    </div>
    <b-modal id="modal-confirm-quiz-modal" :modal-class="action" body-class="p-0" v-model="dialogVisiable" hide-header hide-footer :no-close-on-backdrop="action !== 'ended'" :no-close-on-esc="action !== 'ended'">
      <div class="content-modal" :style="{padding: action === 'ended' ? '40px 32px 32px' : '12px 12px 32px 12px'}">
        <div class="content-confirm py-4 font-weight-normal text-break" :class="{'text-center': (action !== 'pause' && action !== 'continute')}" v-html="notifyText"></div>
        <div class="action-modal text-center">
          <button v-if="action === 'submit'" class="action-cancel bg-white text-primary mr-4 py-2 px-4" @click.prevent="dialogVisiable = false">Hủy</button>
          <button v-if="action === 'ended'" class="action-submit border-0 font-weight-bold bg-primary text-white py-2 px-4" @click.prevent="handerSubmit()">Nộp bài</button>
          <button v-if="action === 'submit'" class="action-submit border-0 font-weight-bold bg-primary text-white py-2 px-4" @click.prevent="handerSubmit()">Nộp bài</button>
          <button v-if="action === 'pause'" class="action-cancel bg-white text-primary mr-4 py-2 px-4" @click.prevent="handerPause()">Thoát</button>
          <button v-if="action === 'pause'" class="action-submit border-0 font-weight-bold bg-primary text-white py-2 px-4" @click.prevent="dialogVisiable = false; startTime()">Tiếp tục làm bài</button>
          <button v-if="action === 'continute'" class="action-cancel bg-white text-primary mr-4 py-2 px-4" @click.prevent="handerQuit()">Thoát</button>
          <button v-if="action === 'continute'" class="action-submit border-0 font-weight-bold bg-primary text-white py-2 px-4" @click.prevent="handerContinute()">Tiếp tục làm bài</button>
          <button v-if="action === 'quit'" class="action-submit border-0 font-weight-bold bg-primary text-white py-2 px-4" @click.prevent="dialogVisiable = false">Tiếp tục làm bài</button>
        </div>
      </div>
    </b-modal>
    <ModalReason v-if="objectId" :object-id="objectId" @reasonSuccess="reasonSuccess"/>
  </div>
</template>

<script>
import Question from '~/components/library/quiz/question.vue'
import ModalReason from '~/components/library/modal/reason.vue'
import Resource from '~/common/api/resource'
const examPauseResource = new Resource('library/exam/pause')
const examContinueResource = new Resource('library/exam/continue')
const examSubmitResource = new Resource('library/exam/submit')
export default {
  components: {
    Question,
    ModalReason
  },
  props: {
    item: {
      type: Object,
      default: null,
      required: false
    }
  },
  data () {
    return {
      detail: this.item,
      timeLeft: (this.item?.time * 60 - this.item?.time_do),
      quizSticky: false,
      processing: false,
      myInterval: null,
      myTimeOut: null,
      started: true,
      dialogVisiable: false,
      notifyText: '',
      action: this.item?.state === 2 ? 'continute' : 'pause',
      objectId: '',
      showNotifyPauseStart: false
    }
  },
  computed: {
    answered () {
      const answers = this.detail.questions.filter(value => value.is_answered !== 0)
      return answers.length
    },
    token () {
      return this.$store.state.token
    }
  },
  beforeMount () {
    window.addEventListener('beforeunload', this.handerQuit)
    window.addEventListener('scroll', this.handerScroll)
  },
  mounted () {
    if (this.action === 'continute') {
      this.dialogVisiable = true
      const questions = this.detail.questions
      const Answer = []
      questions.forEach((item, index) => {
        if (item.is_answered) {
          Answer.push(item)
        }
      })
      const arrTime = this.$helper.convertTimeToDayArr(this.timeLeft)
      this.notifyText = `Trắc nghiệm <b>${this.detail.name}</b>.<br>Thời gian còn lại: <b>${arrTime[2]} phút ${arrTime[3]} giây</b>.<br>Số câu đã làm: ${Answer.length}/${questions.length}`
    }
    this.handerScroll()
    if (this.detail.state === 2) {
      this.showNotifyPauseStart = true
      this.started = false
      setTimeout(() => {
        this.showNotifyPauseStart = false
      }, 3500)
    } else {
      this.started = true
      this.startTime()
    }
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.handerQuit)
    window.removeEventListener('scroll', this.handerScroll)
  },
  methods: {
    handerScroll () {
      // const top = window.scrollY
      // if (top > 80) {
      //   this.quizSticky = true
      // } else {
      //   this.quizSticky = false
      // }
    },
    scrollToQuestion (index) {
      const elmnt = document.getElementById('question-number-' + index)
      elmnt.scrollIntoView({ block: 'center' })
    },
    startTime () {
      if (this.timeLeft <= 0) { return }
      this.myInterval = setInterval(() => {
        this.timeLeft--
        if (this.timeLeft <= 0) {
          clearInterval(this.myInterval)
          this.action = 'ended'
          this.dialogVisiable = true
          this.notifyText = 'Đã hết thời gian làm bài'
          this.myTimeOut = setTimeout(() => {
            this.handerSubmit()
          }, 10000)
        }
      }, 1000)
    },
    handerPause () {
      if (this.processing) { return }
      const data = {
        exam_id: this.detail.id
      }
      this.processing = true
      examPauseResource.store(data)
        .then((res) => {
          if (res.status === 200) {
            clearInterval(this.myInterval)
            this.myInterval = null
            this.started = false
            this.action = ''
            this.dialogVisiable = false
            this.$router.push('/trac-nghiem')
            localStorage.setItem('quiz', this.detail.name)
          }
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err.response.data.message
          })
        })
        .finally(() => {
          this.processing = false
        })
    },
    handerContinute () {
      if (this.detail.state !== 2) {
        return
      }
      if (this.processing) { return }
      const data = {
        exam_id: this.detail.id
      }

      this.processing = true
      examContinueResource.store(data)
        .then((res) => {
          if (res.status === 200) {
            if (this.myInterval) { clearInterval(this.myInterval) }
            this.startTime()
            this.started = true
            this.dialogVisiable = false
            this.detail.state = 1
          }
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err.response.data.message
          })
        })
        .finally(() => {
          this.processing = false
        })
    },
    checkBeforePause () {
      this.action = 'pause'
      this.dialogVisiable = true
      const questions = this.detail.questions
      const Answer = []
      questions.forEach((item, index) => {
        if (item.is_answered) {
          Answer.push(item)
        }
      })
      if (this.myInterval) { clearInterval(this.myInterval) }
      const arrTime = this.$helper.convertTimeToDayArr(this.timeLeft)
      this.notifyText = `Trắc nghiệm <b>${this.detail.name}</b>.<br>Thời gian còn lại: <b>${arrTime[1] !== '00' ? arrTime[1] + ' giờ ' : ''}${arrTime[2]} phút ${arrTime[3]} giây</b>.<br>Số câu đã làm: ${Answer.length}/${questions.length}`
    },
    checkBeforeQuit () {
      this.action = 'quit'
      this.dialogVisiable = true
      this.notifyText = 'Bạn chưa hoàn thành bài trắc nghiệm.<br>Chắc chắn muốn thoát ?'
    },
    checkBeforeSubmit () {
      this.action = 'submit'
      this.dialogVisiable = true
      const questions = this.detail.questions
      const noAnswer = []
      const noAnswerIndex = []
      questions.forEach((item, index) => {
        if (!item.is_answered) {
          noAnswer.push(item)
          noAnswerIndex.push(index + 1)
        }
      })
      if (noAnswer.length) {
        if (noAnswer.length >= 10) {
          this.notifyText = `Bạn vẫn còn ${noAnswer.length} câu chưa hoàn thành.<br>Chắc chắn muốn nộp bài ?`
        } else {
          this.notifyText = `Bạn vẫn còn câu ${noAnswerIndex.join(',')} chưa hoàn thành.<br>Chắc chắn muốn nộp bài ?`
        }
        return
      }
      if (this.timeLeft > 0) {
        const arrTime = this.$helper.convertTimeToDayArr(this.timeLeft)
        this.notifyText = `Thời gian còn lại: <b>${arrTime[1] !== '00' ? arrTime[1] + ' giờ ' : ''}${arrTime[2]} phút ${arrTime[3]} giây</b>.<br>Chắc chắn muốn nộp bài ?`
      }
    },
    handerSubmit () {
      if (this.processing) { return }
      const data = {
        exam_id: this.detail.id
      }
      this.processing = true
      examSubmitResource.store(data)
        .then((res) => {
          if (res.status === 200) {
            if (this.myInterval) { clearInterval(this.myInterval) }
            if (this.myTimeOut) { clearTimeout(this.myTimeOut) }
            // this.$router.push('/trac-nghiem/ket-qua/' + this.detail.slug)
            this.objectId = ''
            this.$emit('success')
          }
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err.response.data.message
          })
        })
        .finally(() => {
          this.processing = false
        })
    },
    handerQuit (e) {
      this.$router.push('/trac-nghiem')
      // e.preventDefault()
      // e = e || window.event
      // if (e) {
      //   e.returnValue = 'Bạn có chắc chắn muốn rời trang này không ?'
      // }
      // return 'Bạn có chắc chắn muốn rời trang này không ?'
    },
    reasonQuestion (questionId) {
      this.objectId = questionId
    },
    reasonSuccess () {
      this.objectId = ''
    }
  }
}
</script>

<style lang="scss">
  .quiz-layout-make-quiz-modal{
    position: relative;
    .quiz-header{
      .quiz-name{
        font-size: 18px;
        line-height: 167.5%;
      }
    }
    .quiz-sub-header{
      border: 0.25px solid #dee2e6;
      box-shadow: 0px 2px 4px #D8DDE6;
      .quiz-time{
        font-size: 20px;
        line-height: 23px;
        img{
          width: 20px;
          margin-right:10px;
        }
      }
      .quiz-pause{
        border: 1px solid var(--primary);
        font-size: 16px;
        width:145px;
        border-radius: 22.5px;
      }
      .quiz-continue{
        font-size: 16px;
        width:145px;
        border-radius: 22.5px;
      }
      .quiz-submit{
        border-radius: 22.5px;
        font-size: 16px;
        padding:12px 34px;
      }
      &.quiz-sticky{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
      }
    }
    .quiz-content{
      .quiz-list-question{
        padding-right:70px;
        .quiz-question-item{
          padding-top:24px;
          padding-bottom:24px;
          border-bottom:0.5px solid #76829C;
          &:first-child{
            padding-top:0;
          }
          &:last-child{
            border-bottom:0;
            padding-bottom:0;
          }
        }
      }
      .quiz-list-short{
        .quiz-sticky{
          position: fixed;
          top: 80px;
        }
        .quiz-list-short-form{
          border: 1px solid #C4c4c4;
          box-shadow: 0px 2px 4px #D8DDE6;
          .title{
            background: #E2E2E2;
            box-shadow: 0px 2px 4px #D8DDE6;
            border-radius: 5px 5px 0px 0px;
            font-size: 20px;
            line-height: 23px;
          }
          .quiz-list-question-short{
            min-height: 40vh;
            max-height: 60vh;
            overflow-x: hidden;
            overflow-y: auto;
            padding:14px 27px 32px 27px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .custom-row{
              display: flex;
              flex-wrap: wrap;
              margin-left: -7px;
              margin-right: -7px;
              > [class*="custom-col-"] {
                padding-right: 7px;
                padding-left: 7px;
                padding-top:10px;
              }
              .custom-col-2{
                flex: 0 0 20%;
                max-width: 20%;
              }
            }
            .quiz-question-answer{
              .number-answer{
                height:38px;
                width:38px;
                background: #E3E3E3;
                border-radius: 50%;
                cursor: pointer;
                .number-answer-character{
                  color: #212121;
                  font-size: 16px;
                  &.big-answer{
                    font-size: 14px;
                  }
                }
              }
              &.primary{
                .number-answer{
                  background:var(--primary);
                  .number-answer-character{
                    color: #FFFFFF;
                  }
                }
              }
              &.success{
                .number-answer{
                  background: #73BF44;
                  .number-answer-character{
                    color: #FFFFFF;
                  }
                }
              }
            }
          }
        }
        .quiz-status{
          padding-top:12px;
          font-size: 14px;
          color: #1B1B1B;
        }
      }
    }
    .notify-pause-start{
      padding:15px 32px;
      border: 1px solid #FFEBCC;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
      border-radius: 4px;
      font-size: 16px;
      width:325px;
      max-width: 100%;
      transform: translateX(-50%);
      left: 50%;
      top: 160px;
      z-index: 1000;
      transition: opacity 1.5s;
    }
  }
  #modal-confirm-quiz-modal{
    &___BV_modal_outer_{
      z-index: 9200 !important;
    }
    &.pause, &.continute{
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
    }
    .modal-dialog{
      min-width:436px;
      min-height:205px;
      .content-modal{
        .content-confirm{
          font-size: 18px;
          color: #212121;
        }
        .action-modal{
          padding-top:20px;
          .action-cancel{
            border: 1px solid var(--primary);
            font-size: 16px;
            width:145px;
            border-radius: 22.5px;
            min-width: 150px;
          }
          .action-submit{
            font-size: 16px;
            padding:12px 34px;
            border-radius: 22.5px;
            min-width: 150px;
          }
        }
      }
    }
  }
</style>
