import { render, staticRenderFns } from "./viewed.vue?vue&type=template&id=997443e8&scoped=true&"
import script from "./viewed.vue?vue&type=script&lang=js&"
export * from "./viewed.vue?vue&type=script&lang=js&"
import style0 from "./viewed.vue?vue&type=style&index=0&id=997443e8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "997443e8",
  null
  
)

export default component.exports